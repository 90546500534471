<template>
	<section>
		<b-modal 
			v-model="isCardModalActive"
			width="auto"
			scroll="keep"
			has-modal-card
			trap-focus
		>
			<form ref="form" onsubmit="return false;">
				<div class="modal-card" style="width: auto">
					<header class="modal-card-head">
						<p class="title is-size-5-desktop is-size-6-mobile">{{ formTitle }}</p>
					</header>
					<section class="modal-card-body">
						<div class="columns is-desktop">
							<div class="column">
								<b-field label="Tingkat Pendidikan*">
									<b-select  
										v-model="active_education.education_level_id" 
										@input="updateEducationLevelName"
										placeholder="Tingkat Pendidikan" 
										expanded
										validation-message="Silahkan isi bagian ini"
										required
									>
										<option 
											v-for="level in education_levels"
											:key="level.id"
											:value="level.id"
											:disabled="level.isDone"
										>{{ level.name }}</option>
									</b-select>
								</b-field>
							</div>
							<div class="column">
								<b-field label="Tempat/Kota*">
									<b-input
										v-model="active_education.location"
										placeholder="Tempat/Kota"
										validation-message="Silahkan isi bagian ini"
										@keypress.native="onlyAlphabet"
										maxlength="50"
										required
									></b-input>
								</b-field>
							</div>
						</div>
						
						<b-field label="Nama Sekolah/Institusi*">
							<b-autocomplete
								v-model="active_education.education_institution_name"
								ref="autocomplete"
								:data="education_institutions"
								:clearable="true"
								:loading="isFetching"
								
								@typing="setPickInstitution"
								field="educ_name"
								placeholder="Input minimal 3 karakter"
								@select="option => setInstituionRecid(option.recid, true)">
								<template slot="footer">
									<a @click="showAddInstitution">
										<span> Tambah Baru... </span>
									</a> 
								</template>
								<template slot="empty">
									Tidak ditemukan hasil untuk
									{{ active_education.education_institution_name }}
								</template>
							</b-autocomplete>
						</b-field>

						<div class="columns is-desktop">
							<div class="column">
								<b-field
									v-if="active_education.education_level_id > 4" 
									label="Jurusan*">
									<b-autocomplete
										v-model="active_education.education_discipline_name"
										ref="autocomplete2"
										:data="education_disciplines"
										:clearable="true"
										:loading="isFetching"
										
										field="educ_name"
										@typing="setPickDiscipline"
										placeholder="Input minimal 3 karakter"
										@select="option => setDisciplineRecid(option.recid, true)">
										<template slot="footer">
											<a @click="showAddDiscipline">
												<span> Tambah Baru... </span>
											</a> 
										</template>
										<template slot="empty">
											Tidak ditemukan hasil untuk
											{{ active_education.education_discipline_name }}
										</template>
									</b-autocomplete>
								</b-field>
							</div>

							<div 
								class="column"
								v-if="active_education.education_level_id >= 10"
							>
								<div class="columns is-mobile">
									<div class="column">
										<b-field label="IPK/Nilai Akhir" message="(Contoh: 3.50)">
											<b-input
												v-model="active_education.gpa"
												placeholder="(Contoh: 3.50)"
												@keypress.native="gpaValidation"
												maxlength="5"
												validation-message="Silahkan isi bagian ini"
												required
											></b-input>
										</b-field>
									</div>
									<div class="column">
										<b-field label="Skala IPK/Nilai Akhir*">
											<b-input
												v-model="active_education.gpa_scale"
												placeholder="(Contoh: 4.00)"
												@keypress.native="gpaValidation"
												maxlength="5"
												validation-message="Silahkan isi bagian ini"
												required
											></b-input>
										</b-field>
									</div>
								</div>
							</div>
						</div>

						<div class="columns is-desktop">
							<div class="column">
								<b-field label="Tahun Mulai*">
									<b-input
										@keypress.native="onlyNumber"
										v-model="active_education.start_year"
										placeholder="Tahun Mulai"
										validation-message="Silahkan isi bagian ini dengan benar"
										maxlength="4"
										minlength="4"
										required
									></b-input>
								</b-field>
							</div>
							<div class="column">
								<b-field label="Tahun Selesai*">
									<b-input
										@keypress.native="onlyNumber"
										v-model="active_education.end_year"
										placeholder="Tahun Selesai"
										validation-message="Silahkan isi bagian ini dengan benar"
										maxlength="4"
										minlength="4"
										required
									></b-input>
								</b-field>
							</div>
						</div>

						<div class="columns is-desktop">
							<div class="column">
								<b-field label="Status Kelulusan*">
									<b-select  
										v-model="active_education.status" 
										placeholder="Status Kelulusan" 
										expanded
										validation-message="Silahkan isi bagian ini"
										required
									>
										<option value="Lulus">Lulus</option>
										<option value="Belum Lulus">Belum Lulus</option>
										<option value="Tidak Lulus">Tidak Lulus</option>
									</b-select>
								</b-field>
							</div>
							<div class="column">
								<b-field label="Nomor Ijazah">
									<b-input
										v-model="active_education.certificate_number"
										placeholder="Nomor Ijazah"
										@keypress.native="ijazahValidation"
										maxlength="50"
									></b-input>
								</b-field>
							</div>
						</div>
					</section>
					<footer class="modal-card-foot is-pulled-right">
						<b-button 
							class="is-pulled-right"  
							@click="closeModal()"
						>Kembali</b-button>
						<b-button 
							class="is-pulled-right"
							type="is-info" 
							@click.prevent="saveEducation"
              :loading="isLoading"
						>Simpan</b-button>
					</footer>
				</div>
			</form>
		</b-modal>
		<b-field>
			<b-button
				class="is-text"
				icon-left="chevron-left"
				@click="prev()"
			>
				Sebelumnya
			</b-button>
		</b-field>
		<div class="columns">
			<div class="column">
				<h4 class="title is-size-5-desktop is-size-6-mobile is-pulled-left"> 
					9 Riwayat Pendidikan Formal
				</h4>
			</div>
		</div>
		<div class="columns">
			<div class="column">
				<b-notification					
					type="is-danger is-light"
					aria-close-label="Close notification"
					role="alert"
					:closable="false">
					<p class="is-size-7-mobile">Ketentuan pengisian form:</p> 
					<p 
                        v-if="minimumEducation == 'sma'"
                        class="is-size-7-mobile has-icon-left has-text-weight-semibold"
                    >
                        <b-icon 
                            icon="check"
                            size="is-small"
                        ></b-icon> 
                        Data Pendidikan Dari SD Hingga SMA Wajib Diisi
                    </p>
                    <p 
                        v-if="minimumEducation == 'sd'"
                        class="is-size-7-mobile has-icon-left has-text-weight-semibold"
                    >
                        <b-icon 
                            icon="check"
                            size="is-small"
                        ></b-icon> 
                        Data Pendidikan SD Wajib Diisi
                    </p>
					<p class="is-size-7-mobile has-icon-left has-text-weight-semibold">
						<b-icon 
							icon="check"
							size="is-small"
						></b-icon> 
						Pengisian Data Pendidikan Mohon Diurutkan Dari SD Terlebih Dahulu Hingga Pendidikan Terakhir
					</p>
				</b-notification>
			</div>
		</div>
		<div class="columns is-desktop">
			<div class="column is-one-quarter is-offset-9">
				<b-field>
					<b-button  
						class="button is-success is-pulled-right" 
						@click="add" 
						:loading="isLoading"
						expanded
					>
						Tambah Data
					</b-button>
				</b-field>
			</div>
		</div>
		<b-table 
			class="box mt-6" 
			:data="educations.length == 0 ? empty : educations" 
			:loading="isLoading"
		>
			<b-table-column
				label="Data Pendidikan Formal"
				:visible="educations.length == 0"
				v-slot="props" 
			>
				{{ props.row }}
			</b-table-column>
			<b-table-column
				label="Nama Sekolah"
				:visible="educations.length != 0"
				v-slot="props" 
			>
				{{ 
					props.row.education_institution_name + " - " + 
					props.row.education_discipline_name + " (" +
					props.row.education_level.name + ")"
				}}
			</b-table-column>
			<b-table-column
				:visible="educations.length != 0"
				field="action"
				label="Aksi"
				v-slot="props" 
				centered
			>
				<b-button  
					class="button is-warning is-light" 
					@click="editEducation(props.row)"
				>Ubah</b-button>
			</b-table-column>
		</b-table>
		<b-field>
			<b-button  
				class="button is-info" 
				@click="next" 
				expanded
				:loading="isLoading"
			>
				Simpan & Lanjut
			</b-button>
		</b-field>
	</section>
</template>

<script>
	import { mapGetters, mapActions } from "vuex"
	import debounce from 'lodash.debounce'
	export default {
		data() {
			return {
				isLoading: false,
				isFetching: false,
				isCardModalActive: false,
				edited_index: -1,

				empty: ['Belum ada data'],
				selectedInstitution: null,
				selectedDiscipline: null,
				minimumEducation: null,

				pickInstitution: false,
				pickDiscipline: false,
				
				educations: [],
				education_levels: [],
        education_institutions: [],
        education_disciplines: [],
				sdLevelList: [2],
				smpLevelList: [3,4],
				smaLevelList: [5,6,7,8,9],
        active_education: {
          id: null,
          semester: null,
          location: '',
          gpa: null,
          gpa_scale: 4.00,
					certificate_number: null,
          start_year: null,
          end_year: null,
          highest: false,
          status: null,
          applicant_id: null,
          education_level_id: null,
          education_level: {
            name: null
          },
					education_institution_id: null,
					education_institution_name: '',
					education_discipline_id: null,
					education_discipline_name: '',
        },
        active_education_default: {
          id: null,
          semester: null,
          location: '',
          gpa: null,
          gpa_scale: 4.00,
					certificate_number: null,
          start_year: null,
          end_year: null,
          highest: false,
          status: null,
          applicant_id: null,
          education_level_id: null,
          education_level: {
            name: null
          },
          education_institution_id: null,
          education_institution_name: '',
          education_discipline_id: null,
          education_discipline_name: '',
        },
			}
		},
		mounted() {
			this.refresh()
			this.checkMinimumEducation()
		},
		computed: {
			...mapGetters({
        applicant: "applicant",
        stepper_position: "stepper_position"
      }),
      formTitle() {
				return this.edited_index === -1 
					? 'Tambah Data Pendidikan' 
					: 'Ubah Data Pendidikan'
			},
		},
		methods: {
			...mapActions({
        setApplicant: "setApplicant",
        setStepperPosition: "setStepperPosition"
      }),

	async checkMinimumEducation() {
        this.refreshing = true
        await this.$axios.get('applicants/' + this.applicant.id + `/formal-educations-by-job`)
        .then(response => {
            this.minimumEducation = response.data.data
        })
        .catch(e => {
            this.message = "Error: " + e.response.data.message
            this.$buefy.snackbar.open({
                message: this.message,
                type: 'is-info',
                position: 'is-top',
                actionText: 'Tutup',
                indefinite: false,
            })
        })
        .finally(() => {
            this.refreshing = false
        })
        },

	async setPickInstitution(name) {
		this.pickInstitution = false
		await this.getAsyncInstitutionData(name)
	},
	async setPickDiscipline(name) {
		this.pickDiscipline = false
		await this.getAsyncDisciplineData(name)
	},
	setInstituionRecid(recid, selected) {
		this.active_education.education_institution_id = recid
		this.pickInstitution = selected
	},
	setDisciplineRecid(recid, selected) {
		this.active_education.education_discipline_id = recid
		this.pickDiscipline = selected
	},

      add() {
        this.isCardModalActive = true
		// NOTE refresh state:active_education and set edited index to -1 (if edited_index = -1, it means new input)
		this.active_education = JSON.parse(JSON.stringify(this.active_education_default))
		this.edited_index = -1
		
        // Object.assign(this.active_education, this.active_education_default)
				// this.mapEducationLevelHasEducation()
      },
      next() {
        const foundSD = this.educations.some(item => item.education_level_id == 2)
				const foundSMP = this.educations.some(item => (item.education_level_id == 3) || (item.education_level_id == 4))
				const foundSMA = this.educations.some(item => (item.education_level_id == 5) || (item.education_level_id == 6) || (item.education_level_id == 7) || (item.education_level_id == 8) || (item.education_level_id == 9))
				// const foundUniv = this.educations.some(item => (item.education_level_id == 10) || (item.education_level_id == 11) || (item.education_level_id == 12) || (item.education_level_id == 13) || (item.education_level_id == 14) || (item.education_level_id == 15))
				if (foundSD && foundSMP && foundSMA && this.minimumEducation == "sma") {
					this.setStepperPosition(9)
				} else if (foundSD && this.minimumEducation == 'sd'){
					this.setStepperPosition(9)
				} else {
					let message = "";
					if (!foundSD) {
						message += "SD"
					}
					if (!foundSMP && this.minimumEducation == 'sma') {
						if (message) {
							message += ", SMP"
						} else {
							message += "SMP"
						}
					}
					if (!foundSMA && this.minimumEducation == 'sma') {
						if (message) {
							message += ", SMA"
						} else {
							message += "SMA"
						}
					}
					this.$buefy.snackbar.open({
						message: 'Silahkan mengisi riwayat pendidikan dari SD. Data '+message+' belum ada',
						type: 'is-info',
						position: 'is-top',
						actionText: 'Tutup',
						indefinite: false,
					})
				}
				// else if (!foundUniv) this.showSnackbar({ text: "Silahkan mengisi riwayat pendidikan dari SD" })
      },
      async refresh() {
        this.refreshing = true
        Object.assign(this.active_education, this.active_education_default)
        
        await this.$axios.get('applicants/' + this.applicant.id + `/formal-educations`)
        .then(response => {
          this.educations = response.data.data
        })
        .catch(e => {
          this.message = "Error: " + e.response.data.message
          this.$buefy.snackbar.open({
						message: this.message,
						type: 'is-info',
						position: 'is-top',
						actionText: 'Tutup',
						indefinite: false,
					})
        })
        .finally(() => {
          this.refreshing = false
        })
        this.refreshing = true
        if (!this.education_levels.length) {
          this.refreshing = true
          this.$axios.get('education-levels')
          .then(async response => {
            this.education_levels = response.data.data.filter(item => {
              // No point to provide 'Tidak Sekolah' option here
              return item.name != 'Tidak Sekolah';
            })
          })
          .catch(e => {
            this.message = "Error: " + e.response.data.message
            this.$buefy.snackbar.open({
							message: this.message,
							type: 'is-info',
							position: 'is-top',
							actionText: 'Tutup',
							indefinite: false,
						})
          })
          .finally(() => {
            this.refreshing = false
          })
        }
			},

			mapEducationLevelHasEducation() {
				console.log(this.education_levels);
				const educationLevels = this.education_levels.map(item => {
					if(this.educations.length > 0) {
						this.educations.forEach(education => {
						if(this.sdLevelList.includes(education.education_level.id) && !this.smpLevelList.includes(education.education_level.id) && !this.smaLevelList.includes(education.education_level.id)) {
							console.log('sd masuk...');
							if(this.smpLevelList.includes(item.id)){
								item.isDone = false
							} else item.isDone = true
						} else if(!this.sdLevelList.includes(education.education_level.id) && this.smpLevelList.includes(education.education_level.id) && !this.smaLevelList.includes(education.education_level.id)) {
							console.log('sd masuk...');
							if(this.smaLevelList.includes(item.id)){
								item.isDone = false
							} else item.isDone = true
						} else if(!this.sdLevelList.includes(education.education_level.id) && !this.smpLevelList.includes(education.education_level.id) && this.smaLevelList.includes(education.education_level.id)) {
							let mergeAllLevelList = [...this.sdLevelList, ...this.smpLevelList, ...this.smaLevelList]

							console.log(mergeAllLevelList);
							if(mergeAllLevelList.includes(item.id)){
								item.isDone = true
							} else item.isDone = false
						}
					});
					} else {
							console.log('else sd masuk...');
							if(this.sdLevelList.includes(item.id))
								item.isDone = false
							else item.isDone = true
						}
					return item
				})

				// console.log(educationLevels);

				this.education_levels = educationLevels.sort((a,b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0))

				// sort((a,b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0))
			},

			getAsyncInstitutionData: debounce(function (name) {
				if (!name.length || name.length < 3) {
					this.education_institutions = []
					return
				}
				this.isFetching = true
				this.$axios.post(process.env.VUE_APP_API_URL + 'master-gateway', {
					code: 'M6',
					DESCRIPTION: name
				})
					.then(({ data }) => {
						this.education_institutions = []
						data.forEach((EDUC_INST) => this.education_institutions.push(EDUC_INST))
					})
					.catch((error) => {
						this.education_institutions = []
						this.message = error.response.data.message;
						this.$buefy.snackbar.open({
							message: this.message,
							type: 'is-info',
							position: 'is-top',
							actionText: 'Tutup',
							indefinite: false,
						})
					})
					.finally(() => {
						this.isFetching = false
					})
			}, 500),

			getAsyncDisciplineData: debounce(function (name) {
				if (!name.length || name.length < 3) {
					this.education_disciplines = []
					return
				}
				this.isFetching = true
				this.$axios.post(process.env.VUE_APP_API_URL + 'master-gateway', {
					code: 'M7',
					DESCRIPTION: 'jur ' + name
				})
					.then(({ data }) => {
						this.education_disciplines = []
						data.forEach((EDUC_DISC) => this.education_disciplines.push(EDUC_DISC))
					})
					.catch((error) => {
						this.education_disciplines = []
						this.message = error.response.data.message;
						this.$buefy.snackbar.open({
							message: this.message,
							type: 'is-info',
							position: 'is-top',
							actionText: 'Tutup',
							indefinite: false,
						})
					})
					.finally(() => {
						this.isFetching = false
					})
			}, 500),

			showAddInstitution() {
				// if (!(/^[A-Za-z0-9\s./()-]+$/i.test(this.active_education.education_institution_name))) {
				// 	this.$buefy.snackbar.open({
				// 			message: "Gagal! Institusi hanya bisa alfabet, angka, spasi dan tanda berikut: - . / ( )",
				// 			type: 'is-info',
				// 			position: 'is-top',
				// 			actionText: 'Tutup',
				// 			indefinite: false,
				// 		})
				// 	return false;
				// }

				this.$buefy.dialog.prompt({
					message: `Nama Sekolah/Institusi`,
					type: 'is-info',
					inputAttrs: {
						placeholder: 'Contoh: Universitas Atma Jaya Yogyakarta',
						value: this.active_education.education_institution_name,
						required: true,
					},
					confirmText: 'Simpan',
					cancelText: 'Kembali',
					onConfirm: (value) => {
						this.confirmAddInstitution(value)
					}
				})
			},
			confirmAddInstitution(value) {
				// if (!(/^[A-Za-z0-9\s./()-]+$/i.test(value))) {
				// 	this.$buefy.snackbar.open({
				// 			message: "Gagal! Institusi hanya bisa alfabet, angka, spasi dan tanda berikut: - . / ( )",
				// 			type: 'is-info',
				// 			position: 'is-top',
				// 			actionText: 'Tutup',
				// 			indefinite: false,
				// 		})
				// 	return false;
				// }
				this.education_institutions.push({educ_name: value})
				this.$refs.autocomplete.setSelected(value)
				this.active_education.education_institution_id = null
				this.pickInstitution = true
			},
			showAddDiscipline() {
				// if (!(/^[A-Za-z0-9\s./()-]+$/i.test(this.active_education.education_discipline_name))) {
				// 	this.$buefy.snackbar.open({
				// 			message: "Gagal! Jurusan hanya bisa alfabet, angka, spasi dan tanda berikut: - . / ( )",
				// 			type: 'is-info',
				// 			position: 'is-top',
				// 			actionText: 'Tutup',
				// 			indefinite: false,
				// 		})
				// 	return false;
				// }

				this.$buefy.dialog.prompt({
					message: `Nama Jurusan`,
					type: 'is-info',
					inputAttrs: {
						placeholder: 'Contoh: Teknik Informatika',
						value: this.active_education.education_discipline_name,
						required: true
					},
					confirmText: 'Simpan',
					cancelText: 'Kembali',
					onConfirm: (value) => {
						this.confirmAddDiscipline(value)
					}
				})
			},
			confirmAddDiscipline(value) {
				// if (!(/^[A-Za-z0-9\s./()-]+$/i.test(value))) {
				// 	this.$buefy.snackbar.open({
				// 			message: "Gagal! Jurusan hanya bisa alfabet, angka, spasi dan tanda berikut: - . / ( )",
				// 			type: 'is-info',
				// 			position: 'is-top',
				// 			actionText: 'Tutup',
				// 			indefinite: false,
				// 		})
				// 	return false;
				// }
				this.education_disciplines.push({educ_name: value})
				this.$refs.autocomplete2.setSelected(value)
				this.active_education.education_discipline_id = null
				this.pickDiscipline = true
			},
      updateEducationLevelName() {
        for (var i = 0; i < this.education_levels.length; i++)  {
          if (this.education_levels[i].id === this.active_education.education_level_id) {
            // if (this.education_levels[i].name == 'SD') {
            //   this.active_education.education_discipline_id = 5637144577
            //   this.active_education.education_discipline_name = 'SD'  
            // } else if (this.education_levels[i].name == 'SMP' || this.education_levels[i].name == 'SLTP') {
            //   this.active_education.education_discipline_id = 5637144578
            //   this.active_education.education_discipline_name = 'SMP'
            // }
			if (this.education_levels[i].id > 9) {
				this.active_education.education_discipline_id = null
				this.active_education.education_discipline_name = ''
				this.active_education.gpa = null
			}
            this.active_education.education_level.name = this.education_levels[i].name
            break
          }
        }
      },
      closeModal() {
        this.isCardModalActive = false
				Object.assign(this.active_education, this.active_education_default)
				this.edited_index = -1
        this.isLoading = false
      },
      async saveEducation(e) {
		// delete leading spaces
		this.active_education.location = this.active_education.location ? this.active_education.location.replace(/^\s*/, "") : null
		this.active_education.certificate_number = this.active_education.certificate_number != null ? this.active_education.certificate_number.length == 1 && (this.active_education.certificate_number == 0 || this.active_education.certificate_number == "0") ? "" : this.active_education.certificate_number : null
this.active_education.certificate_number = this.active_education.certificate_number != null ? this.active_education.certificate_number.length > 1 && this.active_education.certificate_number.trim().length === 1 && (this.active_education.certificate_number.charAt(0) == 0 || this.active_education.certificate_number.charAt(0) == "0") ? "" : this.active_education.certificate_number : null

        if (this.$refs.form.checkValidity()) {
			if (!(/^[A-Za-z\s]+$/i.test(this.active_education.location))) {
				this.$buefy.snackbar.open({
						message: "Gagal! Kota hanya bisa alfabet dan spasi",
						type: 'is-info',
						position: 'is-top',
						actionText: 'Tutup',
						indefinite: false,
					})
				return false;
			}
			// if (!(/^[A-Za-z0-9\s./()-]+$/i.test(this.active_education.education_institution_name))) {
			// 	this.$buefy.snackbar.open({
			// 			message: "Gagal! Institusi hanya bisa alfabet, angka, spasi dan tanda berikut: - . / ( )",
			// 			type: 'is-info',
			// 			position: 'is-top',
			// 			actionText: 'Tutup',
			// 			indefinite: false,
			// 		})
			// 	return false;
			// }
			// if (this.active_education.education_level_id >= 5 && !(/^[A-Za-z0-9\s./()-]+$/i.test(this.active_education.education_discipline_name))) {
			// 	this.$buefy.snackbar.open({
			// 			message: "Gagal! Jurusan hanya bisa alfabet, angka, spasi dan tanda berikut: - . / ( )",
			// 			type: 'is-info',
			// 			position: 'is-top',
			// 			actionText: 'Tutup',
			// 			indefinite: false,
			// 		})
			// 	return false;
			// }
			if (this.active_education.education_level_id >= 10 && (!(/^[0-9.]+$/i.test(this.active_education.gpa)) || !(/^[0-9.]+$/i.test(this.active_education.gpa_scale)))) {
				this.$buefy.snackbar.open({
						message: "Gagal! IPK atau nilai akhir hanya bisa angka dan tanda titik",
						type: 'is-info',
						position: 'is-top',
						actionText: 'Tutup',
						indefinite: false,
					})
				return false;
			}
			if (!(/^[0-9]+$/i.test(this.active_education.start_year)) || !(/^[0-9]+$/i.test(this.active_education.end_year))) {
				this.$buefy.snackbar.open({
						message: "Gagal! Tahun mulai dan akhir hanya bisa angka",
						type: 'is-info',
						position: 'is-top',
						actionText: 'Tutup',
						indefinite: false,
					})
				return false;
			}
			if (this.active_education.certificate_number && !(/^[A-Za-z0-9.,-]+$/i.test(this.active_education.certificate_number))) {
				this.$buefy.snackbar.open({
						message: "Gagal! Ijazah hanya bisa alfabet, angka dan tanda berikut: . - ,",
						type: 'is-info',
						position: 'is-top',
						actionText: 'Tutup',
						indefinite: false,
					})
				return false;
			}
			if (this.active_education.start_year > this.active_education.end_year) {
				this.$buefy.snackbar.open({
						message: "Gagal! Tahun mulai harus lebih kecil atau sama dengan tahun selesai",
						type: 'is-info',
						position: 'is-top',
						actionText: 'Tutup',
						indefinite: false,
					})
				return false;
			}
			if (!this.pickInstitution) {
				this.$buefy.snackbar.open({
					message: "Mohon mengisi nama sekolah dengan memilih dari pilihan yang ada. Jika tidak ada, bisa lakukan tambah baru",
					type: 'is-info',
					position: 'is-top',
					actionText: 'Tutup',
					indefinite: false,
					duration: 7000,
				})
				return false;
			}
			if (!this.pickDiscipline && this.active_education.education_level_id >= 5) {
				this.$buefy.snackbar.open({
					message: "Mohon mengisi nama jurusan dengan memilih dari pilihan yang ada. Jika tidak ada, bisa lakukan tambah baru",
					type: 'is-info',
					position: 'is-top',
					actionText: 'Tutup',
					indefinite: false,
					duration: 7000,
				})
				return false;
			}

			if (this.active_education.education_level_id == 2) {
				this.active_education.education_discipline_id = 5637144577
				this.active_education.education_discipline_name = 'SD'
				this.active_education.gpa = null
			} else if (this.active_education.education_level_id > 2 && this.active_education.education_level_id < 5) {
				this.active_education.education_discipline_id = 5637144578
				this.active_education.education_discipline_name = 'SMP'
				this.active_education.gpa = null
			} else if (this.active_education.education_level_id >=5 && this.active_education.education_level_id < 10) {
				this.active_education.gpa = null
			}
          this.isLoading = true
          this.message = null
          var temp = JSON.parse(JSON.stringify(this.active_education))
          if (this.edited_index > -1) {
            await this.$axios.patch(`applicants/` + this.applicant.id + `/formal-educations/` + this.active_education.id, this.active_education
            )
            .then(response => {
              this.message = response.data.message
              if (response.data.status) {
								Object.assign(this.educations[this.edited_index], temp)
								this.refresh()
                this.closeModal()
              }
            })
            .catch(e => {
              this.message = "Error: " + e.response.data.message
            })
            .finally(() => {
              this.isLoading = false
              this.$buefy.snackbar.open({
								message: this.message,
								type: 'is-info',
								position: 'is-top',
								actionText: 'Tutup',
								indefinite: false,
							}) 
            })
          } else {
            await this.$axios.post(`applicants/` + this.applicant.id + `/formal-educations`, this.active_education
            )
            .then(response => {
              this.message = response.data.message
              if (response.data.status) {
                this.active_education.id = response.data.data.id
								this.educations.push(temp)
								this.refresh()
                this.closeModal()
              }
            })
            .catch(e => {
              this.message = "Error: " + e.response.data.message
            })
            .finally(() => {
              this.isLoading = false
              this.$buefy.snackbar.open({
								message: this.message,
								type: 'is-info',
								position: 'is-top',
								actionText: 'Tutup',
								indefinite: false,
							})
            })
          }
				} else {
          this.message = "Gagal! Data kurang/tidak valid, silahkan cek kembali"
          this.$buefy.snackbar.open({
						message: this.message,
						type: 'is-info',
						position: 'is-top',
						actionText: 'Tutup',
						indefinite: false,
					})
        }
        e.preventDefault()
      },
      editEducation(education) {
				this.pickInstitution = true
				this.pickDiscipline = true
				this.edited_index = this.educations.indexOf(education)
				this.active_education = JSON.parse(JSON.stringify(education))
				if (this.active_education.education_level_id >= 2 && this.active_education.education_level_id < 5) {
					this.active_education.education_discipline_id = null
					this.active_education.education_discipline_name = ''
					this.active_education.gpa = null
				}
				
        this.isCardModalActive = true
      },
			prev() {
				this.setStepperPosition(7)
			},
			onlyNumber($event) {
				let keyCode = $event.keyCode ? $event.keyCode : $event.which;
				if ((keyCode < 48 || keyCode > 57)) {
					$event.preventDefault();
				}
			},
			gpaValidation($event) {
				let keyCode = $event.keyCode ? $event.keyCode : $event.which;
				if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        // 46 is dot
					$event.preventDefault();
				}
			},
			onlyAlphabet($event) {
				let char = String.fromCharCode($event.keyCode);
				if (/^[A-Za-z\s]+$/i.test(char)) { //check if alphabet
					return true;
				} else {
					$event.preventDefault();
					return false;
				}
			},
			onlyAlphaNum($event) {
				let char = String.fromCharCode($event.keyCode);
				if (/^[A-Za-z0-9\s]+$/i.test(char)) {
					return true;
				} else {
					$event.preventDefault();
					return false;
				}
			},
			ijazahValidation($event) {
				let char = String.fromCharCode($event.keyCode);
				if (/^[A-Za-z0-9.,-]+$/i.test(char)) {
					return true;
				} else {
					$event.preventDefault();
					return false;
				}
			},
			// disciplinesAndInstitutionValidation($event) {
			// 	let char = String.fromCharCode($event.keyCode);
			// 	if (/^[A-Za-z0-9\s./()-]+$/i.test(char)) {
			// 		return true;
			// 	} else {
			// 		$event.preventDefault();
			// 		return false;
			// 	}
			// }
		},
	}
</script>